import React from 'react'
import Layout from 'components/layout'
import EquipementBloc from 'components/EquipementBloc/'
import SimpleArticle from 'components/SimpleArticle/'
import ImageParallax from 'components/ImageParallax/'
import Slider from 'components/Slider/'
import { enumTheme } from 'config'
import { getCurrentLang } from 'utils'
import { ParallaxProvider } from 'react-scroll-parallax'
import { getPageTitle } from '../slugs'

export default class VillaPage extends React.Component {
  constructor (props) {
    super(props)
    this.next = this
      .next
      .bind(this)
  }

  next () {
    this
      .slider
      .next()
  }

  render () {
    const {
      data: {
        photosSlider,
        illustrationsPageVillas,
        titreArticleVilla,
        soustitreArticleVilla,
        illustrationArticleVilla,
        texteArticleVilla,
        wifi,
        titreEquipementBloc,
        soustitreEquipementBloc,
        texteColonne1,
        texteColonne2,
        texteColonne3,
      },
      slugs,
      lang
    } = this.props

    return (
      <ParallaxProvider>
        <Layout
          slugs={slugs}
          lang={lang}
          pageTitle={getPageTitle('villas', lang)}
          headerScroll={0}
          footerTheme={enumTheme.WHITE}
          socialMediaIsDark={false}
          socialMediaScroll={0}
          socialMediaOffset={0}
          baseUrl={getCurrentLang(this.props.location.pathname)}
          currentPage={ this.props.currentPage }
        >
          <div className={'villa_slider'}>
            <div style={{ height: 0 }}>
              <div className={'slider_next_button'} onClick={this.next}>

              </div>
            </div>
            <Slider
              theme={enumTheme.PINK}
              photos={photosSlider}
              ref={c => {
                this.slider = c
              }}
            />
          </div>
          <ImageParallax
            img={illustrationsPageVillas[0]}
          />
          <SimpleArticle
            theme={enumTheme.WHITE}
            title={titreArticleVilla}
            subtitle={soustitreArticleVilla}
            img={illustrationArticleVilla}
            text={texteArticleVilla}
            isWifi={wifi}
          />
          <ImageParallax
            img={illustrationsPageVillas[1]}
          />
          <EquipementBloc
            title={titreEquipementBloc}
            subtitle={soustitreEquipementBloc}
            column1={texteColonne1}
            column2={texteColonne2}
            column3={texteColonne3}
          />
        </Layout>
      </ParallaxProvider>
    )
  }
};

import React from 'react'
import Villa from 'templates/villa'
import { graphql } from 'gatsby'
import { getFieldLang } from 'utils'

export default props => {
  const {
    data: {
      allDatoCmsVilla
    },
    pageContext: {
      lang,
      slugs
    }
  } = props

  const data = allDatoCmsVilla && allDatoCmsVilla.edges[0].node

  return (
    <div>
      {data &&
        <Villa
          data={{
            ...data,
            titreArticleVilla: data[`titreArticleVilla${ lang }`],
            soustitreArticleVilla: data[`soustitreArticleVilla${ lang }`],
            texteArticleVilla: data[`texteArticleVilla${ lang }`],
            titreEquipementBloc: data[`titreEquipementBloc${ lang }`],
            soustitreEquipementBloc: data[`soustitreEquipementBloc${ lang }`],
            texteColonne1: data[`texteColonne1${ lang }`],
            texteColonne2: data[`texteColonne2${ lang }`],
            texteColonne3: data[`texteColonne3${ lang }`],
          }}
          location={{ pathname: '/' }}
          currentPage={ props.location.pathname }
          lang={lang}
          slugs={slugs}
        />
      }
    </div>
  )
}

export const query = graphql`
  query VillaQuery($website: String!) {
    allDatoCmsVilla(filter: {website: {eq: $website}}) {
      edges {
        node {
          photosSlider {
            height
            width
            fluid(maxHeight: 800, imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
              sizes
            }
          }
          illustrationsPageVillas {
            height
            width
            fluid(maxWidth: 1920, imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
          titreArticleVillaFr
          titreArticleVillaEn
          titreArticleVillaIt
          titreArticleVillaDe
          soustitreArticleVillaFr
          soustitreArticleVillaEn
          soustitreArticleVillaIt
          soustitreArticleVillaDe
          illustrationArticleVilla {
            fluid(maxWidth: 800,imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
          texteArticleVillaFr
          texteArticleVillaEn
          texteArticleVillaIt
          texteArticleVillaDe
          wifi
          titreEquipementBlocFr
          titreEquipementBlocEn
          titreEquipementBlocIt
          titreEquipementBlocDe
          soustitreEquipementBlocFr
          soustitreEquipementBlocEn
          soustitreEquipementBlocIt
          soustitreEquipementBlocDe
          texteColonne1Fr
          texteColonne1En
          texteColonne1It
          texteColonne1De
          texteColonne2Fr
          texteColonne2En
          texteColonne2It
          texteColonne2De
          texteColonne3Fr
          texteColonne3En
          texteColonne3It
          texteColonne3De
        }
      }
    }
  }
`
